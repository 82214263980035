.input-date-col {
  padding: 0;
}

.input-date-col > div {
  max-width: 100%;
  width: 100%;
}

.pd-5px-force {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

#Itens {
  margin-bottom: 0.7rem;
  border: 1px solid #0d6e2d;
  border-radius: 0.25rem;
}

#Itens button {
  bottom: 0.5rem;
  position: absolute;
}

#Itens .form-label {
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

#Itens .form-group {
  margin-bottom: 0.5rem;
}

#Itens .form-control {
  font-size: 0.7rem;
  /*height: calc(1.7em + 2px);*/
}
#Itens .custom-checkbox {
  font-size: 0.7rem;
}
#Itens .custom-checkbox label {
  padding-top: 0.25rem;
}

#Itens #discount input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#Itens #discount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#Itens #discount input[type="number"] {
  -moz-appearance: textfield;
}

#ItensUploadChain {
  margin-bottom: 0.7rem;
  border: 1px solid #0d6e2d;
  border-radius: 0.25rem;
}

#ItensUploadChain .form-label {
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

#ItensUploadChain .form-group {
  margin-bottom: 0.5rem;
}

#ItensUploadChain .form-control {
  font-size: 0.7rem;
  height: calc(1.7em + 2px);
  padding-left: 4px !important;
}

#FildSetUploadChain fieldset {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

#ItensUploadChain div .col-1 {
  padding-right: 2px !important;
  padding-left: 4px !important;
}

#ItensUploadChain div .col-2 {
  padding-right: 2px !important;
  padding-left: 4px !important;
}

#ItensErrors .form-group {
  margin-bottom: 0.5rem;
}

#AddPresentation {
  bottom: 1rem;
  position: absolute;
}

#productDescription label {
  margin-bottom: 0.3rem;
}

#productDescription {
  font-size: 1em;
  background-color: aliceblue;
}

#ItensErrors button {
  bottom: 0.5rem;
  position: absolute;
}

#ItensErrors {
  margin-bottom: 0.3rem;
  border: 1px solid #d46780;
  border-radius: 0.25rem;
  margin-left: 15px;
}

#SaveUpload {
  float: right;
}

#ItemWarning {
  margin-bottom: 0.3rem;
  border: 1px solid #ffc107;
  border-radius: 0.25rem;
  margin-left: 15px;
}

.buttonDownload {
  margin-top: 32px;
}

.tableProducts {
  font-size: 12px;
  min-width: 1000px !important;
}

.recipeWarning {
  font-size: 12px;
  min-width: 1000px !important;
  color: red;
}
